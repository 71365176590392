import "./Header.scss";

import { HeaderCheck, Logo, Icon } from "../../index";
import React, { Component } from "react";

import { connect } from "react-redux";
import onClickOutside from "react-onclickoutside";
import propTypes from "prop-types";
import { receive } from "../../../store/modules/Painel";
import { withRouter } from "react-router";

const mapStateToProps = (state) => ({
  accesses: state.Painel.painel.data,
});

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isToggleOn: false,
      windowWidth: window.innerWidth,
      loaded: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    const { receive, path, type, check, visitor } = props;

    !check && !visitor && receive(type, path);
  }

  // Atualiza o State com a largura do window.
  handleResize() {
    this.setState({ windowWidth: window.innerWidth });
  }

  removeLoader() {
    const loaderDiv = document.getElementById("vg-loader");

    if (loaderDiv) {
      if (process.env.NODE_ENV === "development") {
        loaderDiv.parentNode.removeChild(loaderDiv);
      } else {
        loaderDiv.classList.add("vg-removing");

        setTimeout(() => {
          loaderDiv.parentNode.removeChild(loaderDiv);
        }, 1000);
      }
    }
  }

  // Atualiza o State com o scroll do window.
  handleScroll() {
    this.setState({ windowScroll: window.scrollY });
  }

  // Adiciona o EventListener de rezise e dispara o handleResize.
  componentDidMount() {
    const { visitor } = this.props;
    visitor && this.removeLoader();
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("scroll", this.handleScroll);
  }

  // Remove o EventListener de rezise.
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.handleScroll);
  }

  // Quando o componente recebe um state novo, ele verifica se a largura excede 1024 e se o menu está aberto
  // E magicamente ele fecha o menu.
  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextState.windowWidth >= 1024 && nextState.isToggleOn) {
      this.handleClickOutside();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  // Evento criado pelo HOC onClickOutside, quando o usuário clica fora do componente atual ele fecha o menu;
  handleClickOutside() {
    this.setState({ isToggleOn: false });
  }

  // Quando o usuário clica no botão do menu, ele abre e fecha o menu, alterando o State do componente.
  toggleMenu() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }

  // Fecha o menu quando o usuário acessa um link.
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.handleClickOutside();
    }

    if (Object.keys(nextProps.accesses.acessos).length && !this.state.loaded) {
      this.setState({
        loaded: true,
      });

      this.removeLoader();
    }
  }

  render() {
    const { accesses, check, visitor } = this.props;
    return (
      <header
        className={`vg-header ${this.state.isToggleOn && "vg-active"} ${
          this.state.windowScroll && "vg-shadow"
        }`}
      >
        <div className="vg-container">
          <Logo check={check} visitor={visitor} />
          {!visitor && (
            <HeaderCheck
              accesses={accesses}
              location={window.location}
              check={check}
            />
          )}
          {accesses.feature_flags &&
            accesses.feature_flags.includes("beamer-vagas") &&
            !accesses.feature_flags.includes("beamer-vagas-automacao") && (
              <div className="container-beamer">
                <div id="beamerButton">
                  <Icon iconKey="beamer" />
                </div>
              </div>
            )}
        </div>
        {/* {!check && !visitor && (
          <Link
            externalLink="https://goo.gl/forms/gT9Js6jgGRe9IIli1"
            textLink={intl.get("BOTAOFEEDBACK.LABEL")}
            icon={"divulgar-vagas"}
            classLink={"vg-btn-feedback"}
          />
        )} */}
      </header>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, { receive })(onClickOutside(Header))
);

Header.defaultProps = {
  accesses: {
    funcionario: {},
    acessos: {},
  },
  visitor: false,
};

Header.propTypes = {
  type: propTypes.string.isRequired,
  path: propTypes.string.isRequired,
  accesses: propTypes.object.isRequired,
  receive: propTypes.func.isRequired,
  location: propTypes.object.isRequired,
  check: propTypes.any,
  visitor: propTypes.bool,
};
